import { createRouter,createWebHistory  } from "vue-router";
import Index from '../pages/index.vue'
export default createRouter({
      history: createWebHistory(),
      routes: [    
                {
                  path: "/",
                  redirect:'/index',
           
                },
                {
                  path: "/index",
                  name: "Index",
                  component:  Index,
                },
                   {
                  path: "/cate",
                  name: "Cate",
                  query:{'cid':100},
                  component: () => import("../pages/cate")
                },
          {
              path: "/cate2",
              name: "Cate2",
              query:{'cid':100},
              component: () => import("../pages/cate2")
          },
          {
              path: "/goodsList",
              name: "GoodsList",
              query:{'cid':100},
              component: () => import("../pages/goodsList")
          },
                  {
                      path: "/search",
                      name: "Search",
                      component: () => import("../pages/search")
                  },
                   {
                  path: "/cart",
                  name: "Cart",
                 component: () => import("../pages/cart")
                },
                {
                    path: "/login",
                    name: "Login",
                    component: () => import("../pages/login")
                  },
                  {
                    path: "/regist",
                    name: "Regist",
                    component: () => import("../pages/regist")
                  },

    ],
});
