<template>
   <div class="line"></div>
    <div class="footer">
             <div class="footup">
                   <ul>
                         <li>
                             <div class="foottitle">关于我们</div>
                             <div class="foottitle2">佳图简介</div>
                             <div class="foottitle2">友情链接</div>
                         </li>
                     <li>
                         <div class="foottitle">联系我们</div>
                         <div class="foottitle2">电话:40010040</div>
                         <div class="foottitle2">微信:40010040</div>
                     </li>
                     <li>
                       <div class="foottitle">友情链接</div>
                       <img class="relationimg" src="http://static.ntimg.cn/original/images/zhaohang_logo.png" alt="">
                     </li>

                     <li style="border-right: 0">
                       <img class="gzhewm" src="../assets/img/wem.jpg" alt="">
                     </li>

                   </ul>
             </div>
              <div>
                <div class="footfont">Copyright © 2021 pic.jcjmcy.com All Rights Reserved 版权所有·嘉佳选图 </div>
                <div class="footfont"> 京ICP备18008961号-1 嘉佳选图是网络服务平台方，若您的权利被侵害，请联系 copyright@jcjmcy.com</div>
              </div>
    </div>
 
</template>
<script>
export default {
  name: 'Footer',
  props: {
    url:{
        type:String,
        default:'login'
    } 
  }
}
</script>

<style scoped>
.line{height: 400px;width: 100%}
.footer{ position: absolute;text-align: center;height:256px;width:100%;line-height:50px;color:#666;font-size:12px;bottom:0;background-color: #f6f6f6;
  border-top: 1px solid #ccc;}
.footer span{padding:10px}

.footup{    width: 1200px;  margin:30px auto;  clear: both;  overflow: hidden;}
.footup ul li{float: left;width:298px;border-right: 1px solid #dfdfdf}
.foottitle{font-weight: bold;font-size: 16px}
.foottitle2{font-size: 14px;    line-height: 25px;}
.gzhewm{width: 100px;height: 100px}
.relationimg{    width: 175px;}
.footfont{line-height: 30px}
</style>