<template>
    <div class="index">
            <Header url="index"></Header>
              
             <swiper  :slides-per-view="1" :space-between="0">

               <swiper-slide  v-for="(item, index) in banner" :key="index"  :virtualIndex="index" >

                 <img :src='item' alt="">
                 <div class="search ">
                     <div style="position:relative;">
                         <input type="text" placeholder="" />
                         <router-link to="search" class="searchbtn bgcolor"><img src="../assets/img/search.png" alt=""></router-link>
                     </div>
                 </div>

                    </swiper-slide>
            </swiper>


            <div class="w12">
                      <div class="notice"><img src="../assets/img/home_notice_ico.jpg" alt="">2021年清明节昵图网放假通知</div>
            </div>

               <div class="goodslist">
                          <div class="clearfix">
                            <router-link to="cate2" class="item" v-for="(item,index) in cate1" :key="index" >
                                   <img v-if="index%2 != 1" src="../assets/img/22.jpg" alt="" />
                                   <img v-else src="../assets/img/11.jpg" alt="" />
                                   <div class="imgtitle line1">类别名称</div>
                              </router-link>
                          </div>
                         <div class="clearfix" style="padding: 30px 0">
                           <router-link to="cate2" class="spanitem line1"  v-for="(item,index) in cate2" :key="index" >推荐类别{{index}}</router-link>
                           <router-link to="cate2" class="spanitem line1"  >查看更多> ></router-link>
                         </div>

                 </div>
                <div class="catebg2" >
                           <div class="goodslist">
                                    <div class="clearfix">
                                        <div class="bigcate"><img src="../assets/img/33.jpg" alt="" />
                                          <div class="imgtitle line1">类别名称</div>
                                        </div>
                                        <div  class="item" v-for="(item,index) in cate3" :key="index" >
                                          <img v-if="index%2 != 1" src="../assets/img/11.jpg" alt="" />
                                          <img v-else src="../assets/img/22.jpg" alt="" />

                                          <div class="imgtitle line1">类别名称</div>
                                        </div>

                                    </div>
                             <div class="clearfix" style="padding: 30px 0">
                               <div class="spanitem line1" v-for="(item,index) in cate4" :key="index" >推荐类别{{index}}</div>
                               <div class="spanitem line1"  v-if="cate.length>10">查看更多> ></div>
                             </div>
                        </div>


                </div>
               <div class=" w12">
                     <ul class="ffooterbox clearfix">
                           <li>
                               <div class="fftitle">精选菜谱</div>
                               <div class="fftitle2">海量共享图任你选择</div>
                               <div class="fftitlebtn">去看看</div>
                           </li>
                       <li>
                         <div class="fftitle">精选菜谱</div>
                         <div class="fftitle2">海量共享图任你选择</div>
                         <div class="fftitlebtn">去看看</div>
                       </li>
                       <li>
                         <div class="fftitle">精选菜谱</div>
                         <div class="fftitle2">海量共享图任你选择</div>
                         <div class="fftitlebtn">去看看</div>
                       </li>
                       <li style="border-right: 0">
                         <div class="fftitle">精选菜谱</div>
                         <div class="fftitle2">海量共享图任你选择</div>
                         <div class="fftitlebtn">去看看</div>
                       </li>
                     </ul>
               </div>
             <Footer />
    </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import {getIndexData} from '../request/api'

export default {
    name: 'App',
    components:{Header,Swiper,SwiperSlide,Footer},
    data() {
        return {
            banner:['http://icon.nipic.com/BannerPic/20210329/home/20210329105753.jpg','http://icon.nipic.com/BannerPic/20210329/home/20210329100913.jpg'],
            cate:[1,1,1,1,1,1,2,3,4,5,6,7,1,2,3,4,5,6,7,1,2,3,4,5,6,7],
            cate1:[1,2,3,4], //4个
            cate2:[1,2,3,4,5,6], //6个+更多
            cate3:[1,1,2,3,4,5], //6+1个大图
            cate4:[1,2,3,45,6,5,5],
            goodslist:[1,2,3,4,5,6,7],
            spans:[1,2,3,4,5,6,7]
        }
     },
    mounted(){ this.getData()},
    methods: {
        getData(){
           getIndexData().then(res=>{
               console.log(res)
           }) 
        }
    }
}
</script>
<style scoped>
 .index .search{position: absolute;left: 50%;top: 50%;z-index: 10;transform: translate(-50%, -50%);overflow: hidden;border-radius: 4px}
 .index .search input{    position: relative; padding-left: 16px;        width: 570px;        height: 48px;        line-height: 48px;
        background-color: #fff;        z-index: 10;        font-size: 14px;        border: 1px solid #e0dfdf;        outline: 0;}
 .index .search .searchbtn{position:absolute;top: 0;right: 0;z-index: 11;    cursor: pointer;}
 .index .search .searchbtn img{width: 30px;    height: 30px;    padding: 9px 20px;}

 .index  .notice{height: 28px;    border: 1px solid #fed9bf;    background-color: #fff9f3;    line-height: 28px;    margin: 35px 10px;}
 .index  .notice img{    width: 15px;    height: 12px;    margin: 0 14px;}

  .index  .goodslist{width: 1260px;overflow: hidden;margin: auto;}
  .index  .goodslist .item{position: relative;width:295px;height:194px;float: left;margin: 10px; cursor: pointer;}
  .index  .goodslist .item:hover .imgtitle,  .index  .goodslist .bigcate:hover .imgtitle{display: block;}
 .index  .goodslist .item .imgtitle, .index  .goodslist .bigcate .imgtitle{display: none;position:absolute;bottom: 0;width: 100%;height: 40px;line-height: 40px;background: #000000ab;color: #fff;text-align: center;}
  .index  .goodslist  .spanitem{float: left;border: 1px solid #dfdfdf;color: #666;width: 137px;height: 44px;line-height: 44px;text-align: center;margin:5px 10px;padding: 0 10px; cursor: pointer;}
 .index  .goodslist  .spanitem:hover{color: #1ebcf0}
.catebg2{background: #f8f8f9;padding-top: 30px}
 .index  .goodslist .bigcate{    height: 418px;position: relative;cursor: pointer;
   width: 305px;
   float: left;
   padding-top: 10px;
   margin-right: 10px;}
 .ffooterbox{    text-align: center;    border: 1px solid #ccc;  padding: 40px 0;margin: 50px 0}
 .ffooterbox li{float: left;width: 300px;    border-right: 1px solid #ccc;}
.fftitle{font-size: 24px;}
.fftitle2{margin: 16px 0;
  color: #999;}
 .fftitlebtn{    width: 145px;
   height: 40px;
   display: inline-block;
   border: 1px solid #1ebcf0;
   line-height: 42px;
   border-radius: 4px;
   color: #1ebcf0;
   margin-top: 15px;
   cursor: pointer;}

</style>