import { get} from '../request/http';

//请求接口方法

export const getIndexData = p => get('index', p);















