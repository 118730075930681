<template>
    <div class="header">
           <div class="w12">
                 <div class="logo"><img src="../assets/img/logo.jpg" alt=""></div>
                  <ul class="pageIndex fl">
                       <router-link to="index"><li :class="url=='index'?'active':''">首页</li></router-link>
                       <router-link to="cate"><li :class="url=='cate'?'active':''">分类</li></router-link>
                       <router-link to="search"><li :class="url=='search'?'active':''">搜索</li></router-link>
                       <router-link to="cart"><li :class="url=='cart'?'active':''">菜谱单</li></router-link>


                </ul>

                 <ul class="login fr">
                       <a href="http://pic.jcjmcy.com/caidan.xlsx"><li>下载模板</li></a>
                   <label for="file"><li :class="url=='cart'?'active':''" >生成菜谱</li></label>
                   <input class="file" id="file" type="file" multiple="multiple" @change="handleFile()"  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />


                   <!--                       <router-link to="login"><li :class="url=='login'?'active':''">登陆</li></router-link>-->
<!--                       <router-link to="regist"><li :class="url=='regist'?'active':''">注册</li></router-link>-->
                </ul>
           </div>
    </div>
    <div class="mtop"></div>  
</template>
<script>
export default {
  name: 'Header',
  props: {
    url:{
        type:String,
        default:'login'
    } 
  },
  methods:{
    handleFile(){
      this.$router.push('/cart')
      this.$emit('upFile',true);
    }
  }
}
</script>

<style scoped>
.header{ position: fixed;left: 0;right: 0;top: 0;background-color: #fff; height: 75px;box-shadow: 0 2px 5px rgb(0 0 0 / 15%); z-index: 110;font-size: 16px;color: #333;}
.header .logo{  float: left;  width: 201px;    height: 59px;margin: 10px;}
.header .pageIndex{      margin-top: 33px;    margin-left: 32px;}
.header ul.pageIndex li{float:left; padding:0 20px;}
.header ul.pageIndex li.active{ color: #1ebcf0;}

.header ul.login li{float: left;  
        width: 108px;
        margin:0 10px;
        border: 1px solid #1ebcf0;
        color: #333;
        height: 36px;
        line-height: 38px;
        text-align: center;
        border-radius: 3px;
        margin-top: 20px;
        text-decoration: none;
        cursor: pointer;}
.header ul.login li.active{  border: 1px solid #1ebcf0;  background: #1ebcf0;color: #fff;}
.header ul.login li:hover{  border: 1px solid #1ebcf0;  background: #1ebcf0;color: #fff;}
.file{width: 0;height: 0;}
</style>